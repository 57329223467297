


























































import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Route } from 'vue-router';
import store from '@/shared/store';

import { isEnded } from '@/shared/lib/campaign_status';
import {
  CancellationFee, Campaign,
} from '@/shared/store/campaign';
import { ReportType } from '@/shared/store/campaignReports';
import AuthRequired from '@/shared/components/AuthRequired';
import LeafLayout from '@/shared/components/site/LeafLayout.vue';
import PageBody from '@/shared/components/campaign/results/PageBody.vue';
import FullScreenModal from '@/shared/components/common/FullScreenModal.vue';
import CancellationConfirmation from '@/zire/views/campaign/CancellationConfirmation.vue';
import ReleaseBrief from '@/shared/components/campaign/results/ReleaseBrief.vue';
import ReleaseDetails from '@/shared/components/campaign/results/ReleaseDetails.vue';
import SlideConfirmButton from '@/shared/components/campaign/results/SlideConfirmButton.vue';
import MobileNudge from '@/shared/components/common/MobileNudge.vue';

@Component({
  components: {
    ReleaseBrief,
    ReleaseDetails,
    SlideConfirmButton,
    LeafLayout,
    PageBody,
    FullScreenModal,
    CancellationConfirmation,
    MobileNudge,
  },
})
export default class Results extends AuthRequired {
  $refs!: {
    exportButton: SlideConfirmButton,
    cancelButton: SlideConfirmButton,
    cancelConfirmationModal: FullScreenModal,
  };

  beforeRouteEnter(to: Route, from: Route, next: any) {
    Promise.all([
      store.dispatch('campaign/load', to.params.id),
      store.dispatch('campaignReports/getReports', {
        campaignId: to.params.id,
        reports: [
          ReportType.Campaign,
          ReportType.Daily,
          ReportType.Country,
          ReportType.Region,
          ReportType.Domain,
          ReportType.Creative,
          ReportType.Image,
          ReportType.Copy,
          ReportType.Demo,
        ],
        countryCodes: [
          'United States',
          'Romania',
          'United Kingdom',
          'Canada',
          'Portugal',
          'Spain',
          'Italy',
          'Greece',
          'Hungary',
          'Australia',
          'Poland',
          'Germany',
          'Czechia',
          'France',
          'Brazil',
          'India',
          'Japan',
          'Netherlands',
          'Belgium',
          'Mexico',
          'Russia',
          'Philippines',
          'Argentina',
          'Sweden',
          'Ireland',
          'Peru',
          'Austria',
          'Colombia',
          'South Africa',
          'Chile',
          'Norway',
          'New Zealand',
          'Switzerland',
          'Turkey',
          'Denmark',
          'Egypt',
          'Republic of Korea',
          'Finland',
          'Taiwan',
          'Uruguay',
          'Costa Rica',
          'China',
          'Iceland',
          'Ecuador',
          'Jamaica',
          'Nicaragua',
          'Ukraine',
          'Hong Kong',
          'Panama',
          'Puerto Rico',
          'Venezuela',
          'Guatemala',
          'Dominican Republic',
          'Paraguay',
        ],
      }),
    ])
      .then(() => next())
      .catch(() => next({ name: 'campaigns' }));
  }

  @Getter('campaign/campaignLoaded') campaignLoaded: boolean;

  @Getter('campaign/campaignDetails') campaign: Campaign;

  @Getter('profile/userId') userId: string;

  get allReports(): any | undefined {
    return this.$store.getters['campaignReports/allReports'](this.campaign.id);
  }

  cancellationFee: CancellationFee = { currency: '', amount: 0 };

  onCancelConfirmationClosed: Function[] = [];

  fadeHeader: boolean = false;

  get isEnded() {
    return isEnded(this.campaign.status!);
  }

  mounted() {}
}
